<template>
	<div id="app" :class="{ videoActive: videoActive }">
		<transition name="load" :duration="800">
			<div v-if="loading" class="loading">
				<div class="layer-1"></div>
				<div class="layer-2"></div>
				<div class="layer-3"></div>
				<div class="layer-4"></div>
			</div>
		</transition>
		<a
			@click="trackClick('visit_norfolk_logo')"
			href="https://www.visitnorfolk.com/"
			target="_blank"
			class="vn-logo"
		>
			<img src="@/assets/img/visit-norfolk-logo.svg" />
		</a>
		<div class="cwb-logo" @click="closeVideo">
			<Lottie
				:options="defaultOptions"
				v-on:animCreated="handleAnimation"
				@mouseenter.native="logoEnter"
				@mouseleave.native="logoLeave"
			/>
		</div>
		<nav class="main-menu" :class="navActive">
			<ul>
				<li class="season1" @click="toggleNav('season1')">Season 1</li>
				<li class="season2" @click="toggleNav('season2')">Season 2</li>
				<li class="season3" @click="toggleNav('season3')">Season 3</li>
				<li class="about" @click="toggleNav('about')">About</li>
			</ul>
		</nav>
		<VideoList
			:class="{ active: navActive === 'season1' }"
			@clicked="playVideo"
			@close="navActive = false"
			:season="1"
		/>
		<VideoList
			:class="{ active: navActive === 'season2' }"
			@clicked="playVideo"
			@close="navActive = false"
			:season="2"
		/>
		<VideoList
			:class="{ active: navActive === 'season3' }"
			@clicked="playVideo"
			@close="navActive = false"
			:season="3"
		/>
		<About
			:class="{ active: navActive === 'about' }"
			@close="navActive = false"
		/>

		<div class="plyr-wrapper" :class="{ active: videoActive }">
			<vue-plyr ref="player" :options="options">
				<video controls crossorigin playsinline data-poster="">
					<source src="" type="" />
				</video>
			</vue-plyr>
		</div>
		<transition name="fade">
			<div
				v-if="!videoActive"
				class="featured"
				@click="playVideo(featuredID)"
				@mouseenter="mouseEnter"
				@mouseleave="mouseLeave"
			>
				<div
					class="custom-cursor"
					v-if="cursor.show"
					:style="{ top: cursor.y + 'px', left: cursor.x + 'px' }"
				>
					<div
						class="bg"
						:style="{ transform: 'rotate(' + cursor.r + 'deg)' }"
					></div>
					<div class="arrow"></div>
				</div>
				<div class="featured__title">
					<h1 v-if="featuredID === 0">
						<span class="episode-number">Welcome to</span>
						The City With Bite
					</h1>
					<h1 v-else>
						<span class="episode-number"
							>S{{ videos[featuredID].season }}: Episode
							{{ videos[featuredID].episode }}</span
						>
						{{ videos[featuredID].title }}
					</h1>
				</div>
				<video autoplay muted loop playsinline class="video-bg">
					<source
						v-if="$screen.portrait === true"
						:src="videos[featuredID].background.portrait"
						type="video/mp4"
					/>
					<source
						v-else
						:src="videos[featuredID].background.landscape"
						type="video/mp4"
					/>
				</video>
			</div>
		</transition>

		<transition name="slide-up">
			<div class="share-btn" v-if="videoActive" @click="modalShare">
				<span>Share <img src="@/assets/img/share-icon.svg"/></span>
			</div>
		</transition>
		<transition name="zoom">
			<Share
				@close="shareActive = false"
				v-if="shareActive"
				:video="nowPlaying"
			/>
		</transition>

		<div class="subscribe-btn" @click="subscribeModal">
			<span>Subscribe <img src="@/assets/img/mail.svg"/></span>
		</div>
		<transition name="zoom">
			<Subscribe @close="subscribeActive = false" v-if="subscribeActive" />
		</transition>
	</div>
</template>

<script>
// @ is an alias to /src
import VideoList from '@/components/VideoList.vue'
import About from '@/components/About.vue'
import Subscribe from '@/components/Subscribe.vue'
import Share from '@/components/Share.vue'
import { mapState, mapActions } from 'vuex'
import Lottie from '@/components/lottie.vue'
import * as animationData from '@/assets/cwb-logo.json'

export default {
	name: 'Home',
	components: {
		VideoList,
		About,
		Subscribe,
		Share,
		Lottie
	},
	metaInfo() {
		return {
			title: this.meta.title,
			meta: [
				{
					name: 'description',
					content: this.meta.description,
					vmid: 'description'
				},
				{
					property: 'og:title',
					content: this.meta.title,
					vmid: 'og:title'
				},
				{
					property: 'og:url',
					content: this.meta.url,
					vmid: 'og:url'
				},
				{
					property: 'og:image',
					content: this.meta.image,
					vmid: 'og:image'
				},
				{
					property: 'og:description',
					content: this.meta.description,
					vmid: 'og:description'
				}
			]
		}
	},
	data() {
		return {
			meta: {
				title: 'The City with Bite',
				url: 'https://www.citywithbite.com/',
				description:
					'Follow foodie fanatic Nomarama as he hits the streets of Norfolk, VA to uncover the stories, the characters, and oh yes, the food that gives the city its bite.',
				image: 'https://www.citywithbite.com/img/og/og.jpg',
				routeFound: false
			},
			defaultOptions: {
				animationData: animationData,
				loop: false,
				autoplay: false
			},
			options: {
				quality: { default: 1080, options: [1080, 720, 540, 360, 240] },
				fullscreen: {
					enabled: true,
					fallback: true,
					iosNative: true
				},
				playsinline: true
			},
			loading: true,
			player: null,
			nowPlaying: null,
			videoActive: false,
			navActive: false,
			subscribeActive: false,
			shareActive: false,
			featuredID: 0,
			cursor: {
				show: false,
				x: 0,
				y: 0,
				r: 0
			}
		}
	},
	computed: mapState(['videos']),
	methods: {
		...mapActions(['setDuration', 'setCurrentTime', 'setWatched']),
		playVideo(index, restart) {
			this.videoActive = true
			this.nowPlaying = this.videos[index]
			if (this.$route.path !== this.nowPlaying.path) {
				this.$router.replace({ path: this.nowPlaying.path })
			}
			const time = this.nowPlaying.currentTime
			const newSource = {
				type: 'video',
				title: this.nowPlaying.title,
				sources: this.nowPlaying.source
			}
			this.player.source = newSource
			this.player.on('loadeddata', () => {
				if (time + 5 < this.player.duration && restart !== true) {
					this.player.off('loadeddata')
					this.player.currentTime = time
				} else {
					this.player.off('loadeddata')
					this.player.currentTime = 0.01
				}
				this.player.play()
			})
		},
		nextVideo(index) {
			const unlockedVideos = this.videos.filter(video => video.locked === false)
			if (index < unlockedVideos.length - 1) {
				index += 1
			} else {
				index = 1
			}
			this.playVideo(index, true)
		},
		closeVideo() {
			this.player.pause()
			this.videoActive = false
			if (this.$route.path !== '/') {
				this.$router.replace({ path: '/' })
			}
		},
		setFeatured(path) {
			const unlockedVideos = this.videos.filter(video => video.locked === false)
			const video = unlockedVideos.find(video => video.path === path)
			if (typeof video !== 'undefined') {
				this.featuredID = video.id
				this.meta.routeFound = true
			} else {
				const unwatched = unlockedVideos.find(video => video.watched === false)
				if (typeof unwatched !== 'undefined') {
					this.featuredID = unwatched.id
				} else {
					this.featuredID = unlockedVideos[unlockedVideos.length - 1].id
				}
			}
		},
		toggleNav(arg) {
			if (this.navActive === arg) {
				this.navActive = false
			} else {
				this.navActive = arg
			}
		},
		mouseEnter() {
			this.cursor.show = true
			this.$el.addEventListener('mousemove', this.mouseMove, false)
		},
		mouseLeave() {
			this.cursor.show = false
		},
		mouseMove(event) {
			this.cursor.x = event.clientX
			this.cursor.y = event.clientY
		},
		trackClick(label) {
			this.$gtag.event('click', {
				event_category: 'link',
				event_label: label
			})
		},
		throttle(fn, threshhold, scope) {
			threshhold || (threshhold = 250)
			var last, deferTimer
			return function() {
				var context = scope || this
				var now = +new Date(),
					args = arguments
				if (last && now < last + threshhold) {
					// hold on to it
					clearTimeout(deferTimer)
					deferTimer = setTimeout(function() {
						last = now
						fn.apply(context, args)
					}, threshhold)
				} else {
					last = now
					fn.apply(context, args)
				}
			}
		},
		handleAnimation(anim) {
			this.anim = anim
			this.anim.goToAndStop(0, true)
			setTimeout(() => {
				this.anim.playSegments([0, 90], true)
			}, 1500)
		},
		logoEnter() {
			this.anim.playSegments([90, 120], false)
		},
		logoLeave() {
			this.anim.playSegments([120, 90], false)
		},
		modalShare() {
			if (this.player.playing) {
				this.player.pause()
			}
			this.shareActive = !this.shareActive
		},
		subscribeModal() {
			if (this.player.playing) {
				this.player.pause()
			}
			this.subscribeActive = !this.subscribeActive
		},
		handleMeta() {
			this.meta.title = `The City with Bite | Season ${
				this.videos[this.featuredID].season
			} Episode ${this.videos[this.featuredID].episode}: ${
				this.videos[this.featuredID].title
			}`
			this.meta.url =
				'https://www.citywithbite.com' + this.videos[this.featuredID].path
			this.meta.description = this.videos[this.featuredID].meta.description
			this.meta.image =
				'https://www.citywithbite.com' + this.videos[this.featuredID].meta.image
		}
	},
	mounted() {
		this.loading = false

		if (document.body.getAttribute('data-prerender') !== true) {
			this.setFeatured(this.$route.path)
			if (this.meta.routeFound) {
				this.handleMeta()
			}
		}
		this.player = this.$refs.player.player

		this.player.on('playing', () => {
			this.$gtag.event('video_play', {
				event_category: 'video',
				event_label: this.nowPlaying.label
			})
			this.setDuration([this.player.duration, this.nowPlaying.id])
		})
		this.player.on('timeupdate', () => {
			this.setCurrentTime([this.player.currentTime, this.nowPlaying.id])
		})
		this.player.on(
			'timeupdate',
			this.throttle(() => {
				var time = Math.floor(this.player.currentTime / 10) * 10
				if (time > 0) {
					this.$gtag.event('video_progress_' + time, {
						event_category: 'video',
						event_label: this.nowPlaying.label
					})
				}
			}, 10000)
		)
		this.player.on('ended', () => {
			this.$gtag.event('video_complete', {
				event_category: 'video',
				event_label: this.nowPlaying.label
			})
			this.setWatched(this.nowPlaying.id)
			this.nextVideo(this.nowPlaying.id)
		})

		setInterval(() => {
			this.cursor.r = Math.floor(Math.random() * 360 + 1)
		}, 250)

		this.$gtag.pageview({
			page_title: this.videos[this.featuredID].title,
			page_path: this.videos[this.featuredID].path
		})
	}
}
</script>

<style lang="scss">
html,
body {
	height: 100%;
	width: 100%;
}

#app {
	position: relative;
	font-family: $paralucent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;

	div {
		position: absolute;
		top: -50%;
		left: 0;
		right: 0;
		bottom: -50%;
		transform: skewY(-15deg);
	}

	.layer-1 {
		background: $orange;
	}
	.layer-2 {
		background: $yellow;
	}
	.layer-3 {
		background: $green;
	}
	.layer-4 {
		background: $cream;
	}
}

.vn-logo {
	position: absolute;
	top: 30px;
	left: 110px;
	z-index: 5;
	transition: 0.25s;

	&:hover {
		transform: scale(1.2);
	}
}
.cwb-logo {
	position: absolute;
	width: 150px;
	height: 120px;
	top: 56px;
	left: 86px;
	z-index: 5;
	cursor: pointer;
}

.main-menu {
	position: absolute;
	top: 0;
	left: 100%;
	z-index: 10;
	transition: 0.5s;

	&.season1 {
		transform: translateX(-200px);
	}

	&.season2 {
		transform: translateX(-200px);
	}

	&.season3 {
		transform: translateX(-200px);
	}

	&.about {
		transform: translateX(-480px);
	}

	ul {
		list-style: none;
		font-family: $komu;
		font-size: 22px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $white;
		transform: rotate(90deg);
		transform-origin: top left;
		display: flex;
		flex-direction: row;
		padding: 0;
		margin: 0;
		padding-left: 40px;

		li {
			position: relative;
			padding-top: 10px;
			width: 170px;
			height: 30px;
			overflow: hidden;
			z-index: 1;
			transition: 0.25s;
			cursor: pointer;

			&:hover {
				padding-top: 20px;
				height: 40px;

				&::before {
					transform: skewY(1deg);
				}
			}

			&::before {
				content: '';
				position: absolute;
				bottom: 10px;
				left: 0;
				right: 0;
				height: 100%;
				z-index: -1;
				transform: skewY(-1deg);
				transition: 0.25s;
			}

			&.season1::before {
				background: $green;
			}
			&.season2::before {
				background: $blue;
			}
			&.season3::before {
				background: $orange;
			}
			&.about::before {
				background: $yellow;
			}
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
	transition: opacity 0.5s, transform 0.5s;
}
.zoom-enter, .zoom-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
	transform: scale(1.2);
}

.slide-up-enter-active,
.slide-up-leave-active {
	transition: transform 0.5s;
}
.slide-up-enter, .slide-up-leave-to /* .fade-leave-active below version 2.1.8 */ {
	transform: translateY(100%);
}

.load-enter-active,
.load-leave-active {
	.layer-1 {
		transition: 0.5s;
		transition-timing-function: ease-in;
		transition-delay: 0.3s;
	}
	.layer-2 {
		transition: 0.5s;
		transition-timing-function: ease-in;
		transition-delay: 0.2s;
	}
	.layer-3 {
		transition: 0.5s;
		transition-timing-function: ease-in;
		transition-delay: 0.1s;
	}
	.layer-4 {
		transition: 0.5s;
		transition-timing-function: ease-in;
	}
}
.load-enter, .load-leave-to /* .fade-leave-active below version 2.1.8 */ {
	div {
		transform: translateY(-100%);
	}
}

.featured {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	cursor: none;

	.custom-cursor {
		position: absolute;
		z-index: 999;
		height: 100px;
		width: 100px;
		margin: -50px 0 0 -50px;

		.bg {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: url('./assets/img/play-bg.svg') center no-repeat;
		}
		.arrow {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: url('./assets/img/play-arrow.svg') center no-repeat;
		}
	}

	&__title {
		position: absolute;
		bottom: 60px;
		left: 80px;
		z-index: 2;
		font-family: $paralucent;
		font-style: normal;
		text-transform: uppercase;
		height: 344px;
		width: 700px;
		background: url('./assets/img/title_bg.png') left center no-repeat;
		display: flex;
		align-items: center;
		padding-left: 54px;

		.new-episode {
			position: absolute;
			top: -31px;
			left: 15px;
			font-family: Paralucent;
			font-style: normal;
			font-weight: $normal;
			font-size: 20px;
			line-height: 20px;
			text-transform: uppercase;
			color: $cream;
			background: $orange;
			padding: 12px 20px;

			span {
				display: block;
				font-weight: $heavy;
			}
		}

		h1 {
			font-weight: $heavy;
			font-size: 70px;
			line-height: 60px;
			transform: skewY(-10deg);
			transform-origin: top left;
			padding-top: 30px;
			width: 520px;
			color: $orange;

			span {
				display: block;
				font-size: 60px;
				line-height: 60px;
				-webkit-text-stroke-width: 2px;
				-webkit-text-fill-color: transparent;
				-webkit-text-stroke-color: $orange;
			}
		}
	}

	.video-bg {
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.subscribe-btn {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 40px;
	width: 200px;
	color: $white;
	line-height: 40px;
	padding-bottom: 10px;
	font-family: $paralucent;
	font-style: normal;
	font-weight: $heavy;
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	background: $orange;
	z-index: 99;
	cursor: pointer;

	img {
		margin-left: 20px;
	}

	span {
		display: inline-block;
		transition: transform 0.25s;
	}

	&:hover span {
		transform: scale(1.2);
	}
}

.share-btn {
	position: absolute;
	bottom: 40px;
	right: 0;
	height: 40px;
	width: 200px;
	color: $white;
	line-height: 40px;
	padding-bottom: 10px;
	font-family: $paralucent;
	font-style: normal;
	font-weight: $heavy;
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	background: $blue;
	z-index: 99;
	cursor: pointer;

	img {
		margin-left: 20px;
	}

	span {
		display: inline-block;
		transition: transform 0.25s;
	}

	&:hover span {
		transform: scale(1.2);
	}
}

.plyr-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;

	&.active {
		display: block;
	}
}

.plyr {
	--plyr-color-main: #fbbc04;
}

.plyr__video-wrapper {
	background: transparent !important;
}

.plyr--video {
	background: #000 !important;
}

.plyr--video .plyr__controls {
	padding-right: 210px !important;
}
.plyr:fullscreen .plyr__controls,
.plyr:-webkit-full-screen .plyr__controls,
.plyr:-moz-full-screen .plyr__controls,
.plyr:-ms-fullscreen .plyr__controls {
	padding-right: 10px !important;
}

@media only screen and (max-width: 1199px) {
	.vn-logo {
		left: 80px;
	}
	.cwb-logo {
		left: 56px;
	}
	.featured__title {
		left: 50px;
		transform: scale(0.8);
		transform-origin: bottom left;
	}
}

@media only screen and (max-width: 959px) {
	.custom-cursor {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.vn-logo {
		width: 75px;
		top: 25px;
		left: 40px;

		img {
			width: 100%;
		}

		&:hover {
			transform: none;
		}
	}
	.cwb-logo {
		width: 115px;
		height: 92px;
		top: 44px;
		left: 25px;
	}
	.subscribe-btn {
		width: 100%;
		left: 0;
		height: 40px;
		padding-bottom: 0;
	}
	.share-btn {
		width: 100%;
		left: 0;
		height: 40px;
		padding-bottom: 0;
	}
	.plyr-wrapper {
		background: #000;
		bottom: 40px;
		padding-bottom: 40px;
	}
	.featured {
		bottom: 40px;
	}
	.main-menu {
		ul {
			padding-left: 0;
		}
		&.season1,
		&.season2,
		&.season3,
		&.about {
			transform: translateX(0);
		}
	}
	.plyr--video .plyr__controls {
		padding-right: 10px !important;
	}

	.zoom-enter-active,
	.zoom-leave-active {
		transition: transform 0.5s;
	}
	.zoom-enter, .zoom-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 1;
		transform: translateY(100%);
	}

	.featured__title {
		bottom: 15px;
		left: 20px;
		transform: scale(0.5);

		.new-episode {
			transform: scale(1.3);
			transform-origin: left bottom;
		}
	}
}
</style>
