import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScreen from 'vue-screen'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
	config: { id: 'G-E7ZTD0P0FD' },
	params: {
		send_page_view: false
	}
})

Vue.config.productionTip = false

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VuePlyr)

Vue.use(VueScreen)

const root = new Vue({
	router,
	store,
	render: h => h(App)
})

document.addEventListener('DOMContentLoaded', function() {
	root.$mount('#app')
})
