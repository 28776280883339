import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'nomarama',
			arrayMerger: (store, saved) => {
				store.forEach((item, i) => {
					Object.assign(item, saved[i])
				})
				return store
			},
			reducer: state => ({
				videos: state.videos.map(function(video) {
					return (({ currentTime, duration, watched }) => ({
						currentTime,
						duration,
						watched
					}))(video)
				})
			})
		})
	],
	state: {
		videos: [
			{
				id: 0,
				season: 1,
				episode: 0,
				title: 'Series Trailer',
				poster: 'trailer.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/intro-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/intro-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/intro-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-intro-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-intro.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/intro/',
				label: 'intro',
				share: {
					facebook: '',
					twitter:
						'Check out the new series, City with Bite! Streaming now citywithbite.com/intro #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama'
				},
				meta: {
					description:
						'Follow foodie fanatic Nomarama as he hits the streets of Norfolk, VA to uncover the stories, the characters, and oh yes, the food that gives the city its bite.',
					image: '/img/og/og.jpg'
				}
			},
			{
				id: 1,
				season: 1,
				episode: 1,
				title: 'My Mama’s Kitchen',
				poster: 'mymamas.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/mymamaskitchen-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/mymamaskitchen-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/mymamaskitchen-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-mymamaskitchen-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-mymamaskitchen.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/my-mamas-kitchen/',
				label: 'my_mamas_kitchen',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 1: My Mama’s Kitchen! Streaming now citywithbite.com/my-mamas-kitchen. Dads love My Mama’s Kitchen’s cornbread. Correction. EVERYONE loves their cornbread. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #mymamaskitchen'
				},
				meta: {
					description:
						'Dads love My Mama’s Kitchen’s cornbread. Correction. EVERYONE loves their cornbread. Just watch.',
					image: '/img/og/mymamaskitchen.jpg'
				}
			},
			{
				id: 2,
				season: 1,
				episode: 2,
				title: 'Blanca',
				poster: 'blanca.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/blanca-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/blanca-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/blanca-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-blanca-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-blanca.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/blanca/',
				label: 'blanca',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 2: Blanca! Streaming now citywithbite.com/blanca. A woman boss, a golden retriever, and a passion for pasta put Blanca on the must-try list. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #blanca'
				},
				meta: {
					description:
						'A woman boss, a golden retriever, and a passion for pasta put Blanca on the must-try list.',
					image: '/img/og/blanca.jpg'
				}
			},
			{
				id: 3,
				season: 1,
				episode: 3,
				title: 'Sam’s Texas Sub Shop',
				poster: 'sams.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/sams-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/sams-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/sams-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-sams-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-sams.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/sams/',
				label: 'sams_texas_sub_shop',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 3: Sam’s Texas Sub Shop! Streaming now citywithbite.com/sams. Visit the local mom-and-pop sub shop that’s putting the chains to shame. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #samstexassubshop'
				},
				meta: {
					description:
						'Sam’s Texas Sub Shop puts the POP in mom and pop. That brisket sub? Whoa. You gotta try this.',
					image: '/img/og/sams.jpg'
				}
			},
			{
				id: 4,
				season: 1,
				episode: 4,
				title: 'Elation Brewing',
				poster: 'elation.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/elation-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/elation-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/elation-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-elation-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-elation.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/elation/',
				label: 'elation',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 4: Elation Brewing! Streaming now citywithbite.com/elation. They’re serving up gluten-reduced brews and quite possibly the best grilled cheese ever made. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #elationbrewing'
				},
				meta: {
					description:
						'Elation Brewing’s serving up gluten-reduced brews and quite possibly the best grilled cheese ever made.',
					image: '/img/og/elation.jpg'
				}
			},
			{
				id: 5,
				season: 1,
				episode: 5,
				title: 'El Rey 2',
				poster: 'elrey2.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/elrey2-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/elrey2-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/elrey2-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-elrey2-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-elrey2.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/elrey2/',
				label: 'el_rey_2',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 5: El Rey 2! Streaming now citywithbite.com/elrey2. Tequila, tacos, AND homemade tortillas? Oh man, this is one tasty episode. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #elrey2'
				},
				meta: {
					description:
						'Tequila, tacos, AND homemade tortillas? Oh man, El Rey 2 makes one tasty episode.',
					image: '/img/og/elrey2.jpg'
				}
			},
			{
				id: 6,
				season: 1,
				episode: 6,
				title: 'Barq Bold Mariner',
				poster: 'boldmariner.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/barq-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/barq-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/barq-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-barq-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-barq.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/barq/',
				label: 'barq',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 6: BarQ/Bold Mariner! Streaming now citywithbite.com/barq. These folks put the “bar” in barbeque with local brews, delicious q, and plenty of laughs. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #barq #boldmariner'
				},
				meta: {
					description:
						'Bar Q and Bold Mariner put the “bar” in barbeque with local brews, delicious q, and plenty of laughs.',
					image: '/img/og/barq.jpg'
				}
			},
			{
				id: 7,
				season: 1,
				episode: 7,
				title: 'Tamarind',
				poster: 'tamarind.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/tamarind-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/tamarind-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/tamarind-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-tamarind-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-tamarind.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/tamarind/',
				label: 'tamarind',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Episode 7: Tamarind! Streaming now citywithbite.com/tamarind. Join us as we head to Norfolk’s Tamarind for a little Indian street food and a lot of spicy conversation. #citywithbite #visitnorfolkva #eatlocal #foodie #virginiaisforlovers #nomarama #tamarind'
				},
				meta: {
					description:
						'Join Nomarama as he heads to Norfolk’s Tamarind for a little Indian street food and a lot of spicy conversation.',
					image: '/img/og/tamarind.jpg'
				}
			},
			{
				id: 8,
				season: 2,
				episode: 1,
				title: 'Syd’s FishPig Cafe',
				poster: 'syds.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/syds-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/syds-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/syds-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-syds-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-syds.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/syds/',
				label: 'syds',
				share: {
					facebook: '',
					twitter:
						'City with Bite, Season 2, Episode 1: Syd’s FishPig Cafe! Join us as we visit the man, the myth, the Syd at Syd’s FishPig Cafe to learn the art of upscale casual. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #sydsfishpigcafe'
				},
				meta: {
					description:
						'We visit the man, the myth, the Syd at Syd’s FishPig Cafe to learn the art of upscale casual. This should be good.',
					image: '/img/og/syds.jpg'
				}
			},
			{
				id: 9,
				season: 2,
				episode: 2,
				title: 'Handsome Biscuit',
				poster: 'handsomebiscuit.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/handsomebiscuit-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/handsomebiscuit-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/handsomebiscuit-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-handsomebiscuit-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-handsomebiscuit.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/handsomebiscuit/',
				label: 'handsomebiscuit',
				share: {
					facebook: '',
					twitter:
						'With sweet potato biscuits this good look’n, it’s easy to see how Handsome Biscuit got its name. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #handsomebiscuit'
				},
				meta: {
					description:
						'With sweet potato biscuits this good look’n, it’s easy to see how Handsome Biscuit got its name.',
					image: '/img/og/handsomebiscuit.jpg'
				}
			},
			{
				id: 10,
				season: 2,
				episode: 3,
				title: 'Brothers',
				poster: 'brothers.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/brothers-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/brothers-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/brothers-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-brothers-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-brothers.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/brothers/',
				label: 'brothers',
				share: {
					facebook: '',
					twitter:
						'Watch how a NBA ref turned fine-dining all-star jazzes up Nofolk’s downtown food scene at Brothers. This should be good. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #brothers #jazz'
				},
				meta: {
					description:
						'Watch how a NBA ref turned fine-dining all-star jazzes up Nofolk’s downtown food scene at Brothers. This should be good.',
					image: '/img/og/brothers.jpg'
				}
			},
			{
				id: 11,
				season: 2,
				episode: 4,
				title: 'La Brioche',
				poster: 'labrioche.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/labrioche-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/labrioche-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/labrioche-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-labrioche-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-labrioche.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/labrioche/',
				label: 'labrioche',
				share: {
					facebook: '',
					twitter:
						'Nomarama breaks bread at French bakery, La Brioche in Norfolk’s NEON District and tries some local goodness, right out of the oven. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #labrioche'
				},
				meta: {
					description:
						'Nomarama breaks bread at French bakery, La Brioche in Norfolk’s NEON District and tries some local goodness, right out of the oven.',
					image: '/img/og/labrioche.jpg'
				}
			},
			{
				id: 12,
				season: 2,
				episode: 5,
				title: 'Crudo Nudo',
				poster: 'crudonudo.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/crudonudo-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/crudonudo-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/crudonudo-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-crudonudo-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-crudonudo.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/crudonudo/',
				label: 'crudonudo',
				share: {
					facebook: '',
					twitter:
						'We stop by Crudo Nudo in Norfolk’s historic Ghent Neighborhood for some Spanish faire, with flair. This should be good. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #crudonudo #tapas'
				},
				meta: {
					description:
						'We stop by Crudo Nudo in Norfolk’s historic Ghent Neighborhood for some Spanish faire, with flair. This should be good.',
					image: '/img/og/crudonudo.jpg'
				}
			},
			{
				id: 13,
				season: 2,
				episode: 6,
				title: 'Strawberry Fields',
				poster: 'strawberryfields.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/strawberryfields-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/strawberryfields-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/strawberryfields-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-strawberryfields-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-strawberryfields.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/strawberryfields/',
				label: 'strawberryfields',
				share: {
					facebook: '',
					twitter:
						'Get the real scoop on authentic gelato at Strawberry Fields in Ghent. This should be good. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #strawberryfields #gelato'
				},
				meta: {
					description:
						'Get the real scoop on authentic gelato at Strawberry Fields in Ghent. This should be good.',
					image: '/img/og/strawberryfields.jpg'
				}
			},
			{
				id: 14,
				season: 2,
				episode: 7,
				title: 'Benchtop',
				poster: 'benchtop.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/benchtop-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/benchtop-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/benchtop-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-benchtop-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-benchtop.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/benchtop/',
				label: 'benchtop',
				share: {
					facebook: '',
					twitter:
						'It’s time to get beer nerdy and learn the science behind brewing from a real scientist at Benchtop Brewing in Chelsea. This should be good. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #benchtop'
				},
				meta: {
					description:
						'It’s time to get beer nerdy and learn the science behind brewing from a real scientist at Benchtop Brewing in Chelsea. This should be good. ',
					image: '/img/og/benchtop.jpg'
				}
			},
			{
				id: 15,
				season: 2,
				episode: 8,
				title: 'Omar’s Carriage House',
				poster: 'omars.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/omars-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/omars-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/omars-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-omars-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-omars.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/omars/',
				label: 'omars',
				share: {
					facebook: '',
					twitter:
						'We share some food and ghost stories at Omar’s Carriage House in Norfolk’s Historic Freemason. This should be good. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #omarscarriagehouse'
				},
				meta: {
					description:
						'We share some food and ghost stories at Omar’s Carriage House in Norfolk’s Historic Freemason. This should be good.',
					image: '/img/og/omars.jpg'
				}
			},
			{
				id: 16,
				season: 3,
				episode: 1,
				title: 'Yorgos',
				poster: 'yorgos.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/yorgos-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/yorgos-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/yorgos-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-yorgos-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-yorgos.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/yorgos/',
				label: 'yorgos',
				share: {
					facebook: '',
					twitter:
						'Yorgo’s Bageldashery takes breakfast to the next level. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #yorgos'
				},
				meta: {
					description:
						'Yorgo’s Bageldashery takes breakfast to the next level.',
					image: '/img/og/yorgos.jpg'
				}
			},
			{
				id: 17,
				season: 3,
				episode: 2,
				title: 'Alkaline',
				poster: 'alkaline.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/alkaline-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/alkaline-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/alkaline-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-alkaline-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-alkaline.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/alkaline/',
				label: 'alkaline',
				share: {
					facebook: '',
					twitter:
						'Noodle around with Alkaline in Norfolk’s Ghent. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #alkaline'
				},
				meta: {
					description: 'Noodle around with Alkaline in Norfolk’s Ghent.',
					image: '/img/og/alkaline.jpg'
				}
			},
			{
				id: 18,
				season: 3,
				episode: 3,
				title: 'Vessel',
				poster: 'vessel.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/vessel-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/vessel-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/vessel-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-vessel-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-vessel.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/vessel/',
				label: 'vessel',
				share: {
					facebook: '',
					twitter:
						'There’s a latte to love about Vessel Craft Coffee. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #vessel'
				},
				meta: {
					description: 'There’s a latte to love about Vessel Craft Coffee.',
					image: '/img/og/vessel.jpg'
				}
			},
			{
				id: 19,
				season: 3,
				episode: 4,
				title: 'Luce',
				poster: 'luce.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/luce-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/luce-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/luce-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-luce-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-luce.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/luce/',
				label: 'luce',
				share: {
					facebook: '',
					twitter: 'Italian soul meets rock and roll at Luce in Downtown. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #luce'
				},
				meta: {
					description: 'Italian soul meets rock and roll at Luce in Downtown.',
					image: '/img/og/luce.jpg'
				}
			},
			{
				id: 20,
				season: 3,
				episode: 5,
				title: 'Hummingbird Macarons',
				poster: 'hummingbird.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/hummingbird-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/hummingbird-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/hummingbird-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-hummingbird-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-hummingbird.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/hummingbird/',
				label: 'hummingbird',
				share: {
					facebook: '',
					twitter: 'Let’s munch on some Macarons at Hummingbird in Norfolk. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #hummingbirdmacarons'
				},
				meta: {
					description: 'Let’s munch on some Macarons at Hummingbird in Norfolk.',
					image: '/img/og/hummingbird.jpg'
				}
			},
			{
				id: 21,
				season: 3,
				episode: 6,
				title: 'Public House',
				poster: 'public-house.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/publichouse-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/publichouse-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/publichouse-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-publichouse-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-publichouse.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/publichouse/',
				label: 'publichouse',
				share: {
					facebook: '',
					twitter: 'Spirits are high at Norfolk’s Public House. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #publichouse'
				},
				meta: {
					description: 'Spirits are high at Norfolk’s Public House.',
					image: '/img/og/publichouse.jpg'
				}
			},
			{
				id: 22,
				season: 3,
				episode: 7,
				title: 'Doumar’s',
				poster: 'doumars.jpg',
				source: [
					{
						src: 'https://assets.citywithbite.com/videos/doumars-540.mp4',
						type: 'video/mp4',
						size: 540
					},
					{
						src: 'https://assets.citywithbite.com/videos/doumars-720.mp4',
						type: 'video/mp4',
						size: 720
					},
					{
						src: 'https://assets.citywithbite.com/videos/doumars-1080.mp4',
						type: 'video/mp4',
						size: 1080
					}
				],
				background: {
					portrait: 'https://assets.citywithbite.com/videos/sv-doumars-vert.mp4',
					landscape: 'https://assets.citywithbite.com/videos/sv-doumars.mp4'
				},
				currentTime: 0,
				duration: null,
				watched: false,
				locked: false,
				path: '/doumars/',
				label: 'doumars',
				share: {
					facebook: '',
					twitter: 'Nostalgia and Limeade are on the menu at Doumar’s. #citywithbite #visitnorfolkva #foodie #virginiaisforlovers #nomarama #doumars'
				},
				meta: {
					description: 'Nostalgia and Limeade are on the menu at Doumar’s.',
					image: '/img/og/doumars.jpg'
				}
			}
		]
	},
	mutations: {
		setDuration(state, [duration, id]) {
			state.videos[id].duration = duration
		},
		setCurrentTime(state, [currentTime, id]) {
			state.videos[id].currentTime = currentTime
		},
		setWatched(state, id) {
			state.videos[id].watched = true
		}
	},
	actions: {
		setDuration({ commit }, payload) {
			commit('setDuration', payload)
		},
		setCurrentTime({ commit }, payload) {
			commit('setCurrentTime', payload)
		},
		setWatched({ commit }, payload) {
			commit('setWatched', payload)
		}
	},
	modules: {}
})
