<template>
	<div class="subscribe-container" @click.self="close()">
		<div class="subscribe-wrapper">
			<div class="close" @click="close()"></div>
			<h2>Request VisitNorfolk's e-Newsletter</h2>
			<p>
				Want to know more about what's happening in Norfolk? Subscribe to our
				monthly e-newsletter to receive information about events, promotions and
				special offers.
			</p>
			<div class="e2ma_signup_form" id="e2ma_signup_form">
				<div class="e2ma_signup_form_container" id="e2ma_signup_form_container">
					<form
						method="post"
						id="e2ma_signup"
						onSubmit="return signupFormObj.checkForm(this)"
						action="https://app.e2ma.net/app2/audience/signup/1939288/1914753/?force_v1=1"
					>
						<input
							type="hidden"
							name="prev_member_email"
							id="id_prev_member_email"
						/><input type="hidden" name="source" id="id_source" /><input
							type="hidden"
							name="group_13024129"
							value="13024129"
							id="id_group_13024129"
						/>
						<input type="hidden" name="private_set" value="{num_private}" />
						<div class="e2ma_signup_form_row">
							<div class="e2ma_signup_form_label">
								Email<span class="e2ma_signup_form_required_asterix">*</span>
							</div>
							<div class="e2ma_signup_form_element">
								<input
									type="email"
									name="email"
									required
									id="id_email"
									placeholder="Email *"
								/>
							</div>
						</div>
						<div class="e2ma_signup_form_row half">
							<div class="e2ma_signup_form_label">First Name</div>
							<div class="e2ma_signup_form_element">
								<input
									type="text"
									name="member_field_first_name"
									field_id="2571137"
									id="id_member_field_first_name"
									placeholder="First Name *"
								/>
							</div>
						</div>
						<div class="e2ma_signup_form_row half">
							<div class="e2ma_signup_form_label">Last Name</div>
							<div class="e2ma_signup_form_element">
								<input
									type="text"
									name="member_field_last_name"
									field_id="2572161"
									id="id_member_field_last_name"
									placeholder="Last Name *"
								/>
							</div>
						</div>
						<div class="e2ma_signup_form_row">
							<div class="e2ma_signup_form_label">City</div>
							<div class="e2ma_signup_form_element">
								<input
									type="text"
									name="member_field_city"
									field_id="2875265"
									id="id_member_field_city"
									placeholder="City"
								/>
							</div>
						</div>
						<div class="e2ma_signup_form_row half">
							<div class="e2ma_signup_form_label">State</div>
							<div class="e2ma_signup_form_element">
								<select
									name="member_field_state"
									field_id="2876289"
									id="id_member_field_state"
								>
									<option value="" disabled selected>State</option>
									<option value="Alabama">Alabama</option>
									<option value="Alaska">Alaska</option>
									<option value="Arizona">Arizona</option>
									<option value="Arkansas">Arkansas</option>
									<option value="Armed Forces America"
										>Armed Forces America</option
									>
									<option value="Armed Forces Europe"
										>Armed Forces Europe</option
									>
									<option value="Armed Forces Pacific"
										>Armed Forces Pacific</option
									>
									<option value="California">California</option>
									<option value="Colorado">Colorado</option>
									<option value="Connecticut">Connecticut</option>
									<option value="Delaware">Delaware</option>
									<option value="Florida">Florida</option>
									<option value="Georgia">Georgia</option>
									<option value="Hawaii">Hawaii</option>
									<option value="Idaho">Idaho</option>
									<option value="Illinois">Illinois</option>
									<option value="Indiana">Indiana</option>
									<option value="Iowa">Iowa</option>
									<option value="Kansas">Kansas</option>
									<option value="Kentucky">Kentucky</option>
									<option value="Louisiana">Louisiana</option>
									<option value="Maine">Maine</option>
									<option value="Maryland">Maryland</option>
									<option value="Massachusetts">Massachusetts</option>
									<option value="Michigan">Michigan</option>
									<option value="Minnesota">Minnesota</option>
									<option value="Mississippi">Mississippi</option>
									<option value="Missouri">Missouri</option>
									<option value="Montana">Montana</option>
									<option value="Nebraska">Nebraska</option>
									<option value="Nevada">Nevada</option>
									<option value="New Hampshire">New Hampshire</option>
									<option value="New Jersey">New Jersey</option>
									<option value="New Mexico">New Mexico</option>
									<option value="New York">New York</option>
									<option value="North Carolina">North Carolina</option>
									<option value="North Dakota">North Dakota</option>
									<option value="Ohio">Ohio</option>
									<option value="Oklahoma">Oklahoma</option>
									<option value="Oregon">Oregon</option>
									<option value="Pennsylvania">Pennsylvania</option>
									<option value="Rhode Island">Rhode Island</option>
									<option value="South Carolina">South Carolina</option>
									<option value="South Dakota">South Dakota</option>
									<option value="Tennessee">Tennessee</option>
									<option value="Texas">Texas</option>
									<option value="Utah">Utah</option>
									<option value="Vermont">Vermont</option>
									<option value="Virginia">Virginia</option>
									<option value="Washington">Washington</option>
									<option value="Washington, D.C.">Washington, D.C.</option>
									<option value="West Virginia">West Virginia</option>
									<option value="Wisconsin">Wisconsin</option>
									<option value="Wyoming">Wyoming</option>
								</select>
							</div>
						</div>
						<div class="e2ma_signup_form_row half">
							<div class="e2ma_signup_form_label">Postal Code</div>
							<div class="e2ma_signup_form_element">
								<input
									type="text"
									name="member_field_postal_code"
									field_id="2877313"
									id="id_member_field_postal_code"
									placeholder="Postal Code"
								/>
							</div>
						</div>
						<div
							class="e2ma_signup_form_button_row"
							id="e2ma_signup_form_button_row"
						>
							<input
								id="e2ma_signup_submit_button"
								class="e2ma_signup_form_button"
								type="submit"
								name="Submit"
								value="Submit"
							/>
						</div>
					</form>
				</div>
			</div>
			<div class="disclaimer">
				By clicking submit I consent to receive email as stated by these
				terms.<br />* = required fields
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Subscribe',
	methods: {
		close() {
			this.$emit('close')
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subscribe-container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(38, 38, 38, 0.5);
	z-index: 100;
	transition: 0.5s;
	align-items: center;
	justify-content: center;
}

.subscribe-wrapper {
	position: relative;
	width: 420px;
	padding: 30px 50px;
	background: $cream url('../assets/img/dot-bg.png') repeat-y top left;

	h2 {
		font-weight: $bold;
		font-size: 25px;
		line-height: 25px;
		margin: 0 0 15px;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: $orange;
	}
	p {
		font-weight: $light;
		font-size: 12px;
		line-height: 15px;
		margin: 0 0 15px;
		letter-spacing: 0.02em;
	}
	.disclaimer {
		font-weight: $light;
		font-size: 10px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 0.02em;
	}
}

.e2ma_signup_form {
	padding: 0;
	font-family: $paralucent;

	form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	input[type='text'],
	input[type='email'],
	textarea,
	select[multiple='multiple'] {
		font-family: $paralucent;
		border: 1px solid $orange;
		color: $black;
		font-size: 12px;
		line-height: 12px;
		font-weight: $light;
		letter-spacing: 0.02em;
		height: 32px;
		padding: 0 15px;
		width: 100%;
		border-radius: 16px;

		&::placeholder {
			color: $black;
		}

		&:hover,
		&:focus {
			border-color: $green;
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		font-family: $paralucent;
		border: 1px solid $orange;
		color: $black;
		font-size: 12px;
		line-height: 12px;
		font-weight: $light;
		letter-spacing: 0.02em;
		height: 32px;
		padding: 0 30px 0 15px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		border-radius: 16px;
		background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.24268L5.94975 6.19242L10.8995 1.24268" stroke="%23E64225" stroke-width="2"/></svg>');
		background-position: right 15px center;
		background-repeat: no-repeat;

		&:hover,
		&:focus {
			outline: none;
			border-color: $green;
			background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.24268L5.94975 6.19242L10.8995 1.24268" stroke="%233ba891" stroke-width="2"/></svg>');
		}
	}

	input[type='submit'],
	.e2ma_signup_form_button {
		display: block;
		height: 32px;
		padding: 0 15px;
		width: 100%;
		margin: 0 auto;
		max-width: 150px;
		border-radius: 16px;
		font-family: $paralucent;
		font-style: normal;
		font-weight: $heavy;
		font-size: 16px;
		text-transform: uppercase;
		color: $cream;
		background: $orange;

		&:hover,
		&:focus {
			outline: none;
			background: $green;
		}
	}

	&_row {
		width: 100%;
		margin-bottom: 8px;

		&.half {
			width: calc(50% - 4px);
		}
	}

	&_button_row {
		width: 100%;
		margin: 0;
	}

	&_element {
		margin-top: 0;
	}

	&_label {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.subscribe-container {
		background: $cream url('../assets/img/dot-bg.png') repeat-y top left;
		padding: 0 40px 50px;
	}

	.subscribe-wrapper {
		position: static;
		max-width: 420px;
		width: 100%;
		background: none;
		padding: 0;
	}
}
</style>
