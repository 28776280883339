<template>
	<div class="share-container" @click.self="close()">
		<div class="share-wrapper">
			<div class="close" @click="close()"></div>
			<h2>Share</h2>
			<div class="share-icons">
				<div @click="facebookPopup()" class="share-icon">
					<img src="@/assets/img/share-facebook.svg" />Facebook
				</div>
				<div @click="twitterPopup()" class="share-icon">
					<img src="@/assets/img/share-twitter.svg" />Twitter
				</div>
				<div class="share-icon" @click="handleCopy">
					<img src="@/assets/img/share-link.svg" />
					<transition name="slide-fade">
						<div v-if="copied" key="copied">
							Copied
						</div>
						<div v-else key="copy">
							Copy link
						</div>
					</transition>
				</div>
			</div>
			<textarea
				ref="url"
				:value="'https://www.citywithbite.com' + video.path"
			></textarea>
		</div>
	</div>
</template>

<script>
export default {
	name: 'share',
	props: {
		video: Object
	},
	data() {
		return {
			copied: false
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
		handleCopy() {
			this.$refs.url.select()
			document.execCommand('copy')
			this.copied = true
		},
		facebookPopup() {
			const url =
				'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcitywithbite.com' +
				encodeURIComponent(this.video.path)
			this.popupCenter(url, 'fbShareWindow', 480, 450)
		},
		twitterPopup() {
			const url =
				'https://twitter.com/intent/tweet?text=' +
				encodeURIComponent(this.video.share.twitter)
			this.popupCenter(url, 'twitterShareWindow', 480, 254)
		},
		popupCenter(url, title, w, h) {
			// Fixes dual-screen position                             Most browsers      Firefox
			const dualScreenLeft =
				window.screenLeft !== undefined ? window.screenLeft : window.screenX
			const dualScreenTop =
				window.screenTop !== undefined ? window.screenTop : window.screenY

			const width = window.innerWidth
				? window.innerWidth
				: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: screen.width
			const height = window.innerHeight
				? window.innerHeight
				: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: screen.height

			const systemZoom = width / window.screen.availWidth
			const left = (width - w) / 2 / systemZoom + dualScreenLeft
			const top = (height - h) / 2 / systemZoom + dualScreenTop
			const newWindow = window.open(
				url,
				title,
				`
		      scrollbars=yes,
		      width=${w / systemZoom},
		      height=${h / systemZoom},
		      top=${top},
		      left=${left}
		      `
			)

			if (window.focus) newWindow.focus()
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.share-container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(38, 38, 38, 0.5);
	z-index: 100;
	transition: 0.5s;
	align-items: center;
	justify-content: center;
}

.share-wrapper {
	position: relative;
	width: 420px;
	padding: 30px 50px;
	background: $cream url('../assets/img/dot-bg.png') repeat-y top left;

	h2 {
		font-weight: $bold;
		font-size: 25px;
		line-height: 25px;
		margin: 0 0 15px;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: $blue;
	}
	textarea {
		position: absolute;
		top: -9999px;
	}
	.share-icons {
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.share-icon {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: 15px;
		font-family: $paralucent;
		font-style: normal;
		font-weight: $light;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.02em;
		text-decoration: none;
		color: $black;

		img {
			margin-bottom: 15px;
			transition: 0.25s;
		}

		&:hover img {
			transform: scale(1.2);
		}

		div {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
		}
	}
}

.close::before,
.close::after {
	background: $blue;
}
.close:hover {
	background: $blue;
}

.slide-fade-enter-active {
	transition: all 0.3s ease;
}
.slide-fade-leave-active {
	transition: all 0.3s ease;
}
.slide-fade-enter {
	transform: translateY(15px);
	opacity: 0;
}
.slide-fade-leave-to {
	transform: translateY(-15px);
	opacity: 0;
}

@media only screen and (max-width: 767px) {
	.share-container {
		background: $cream url('../assets/img/dot-bg.png') repeat-y top left;
		padding: 0 40px 50px;
	}

	.share-wrapper {
		position: static;
		max-width: 420px;
		width: 100%;
		background: none;
		padding: 0;

		h2 {
			text-align: center;
		}
	}
}
</style>
