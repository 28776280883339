<template>
	<div class="video-list-container">
		<div class="close" @click="$emit('close')"></div>
		<ul class="video-list">
			<li
				v-for="video in seasonVideos"
				:key="video.id"
				@click="selectVideo(video.id)"
				:class="{ locked: video.locked }"
			>
				<div
					class="thumbnail"
					:style="{
						backgroundImage: `url(${require('@/assets/img/thumbnails/' +
							video.poster)})`
					}"
				></div>
				<div v-if="video.locked" class="thumbnail-overlay"></div>
				<div v-if="video.locked" class="soon">Coming Soon</div>
				<div class="title">
					<div class="episode-number">Episode {{ video.episode }}</div>
					{{ video.title }}
				</div>
				<div
					class="progress-bar"
					:style="{ width: (video.currentTime / video.duration) * 100 + '%' }"
				></div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'VideoList',
	data() {
		return {
			width: 30,
			activeColor: 'red',
			fontSize: 30
		}
	},
	props: {
		season: Number
	},
	computed: {
		seasonVideos() {
			return this.$store.state.videos.filter(
				video => video.season === this.season
			)
		},
		...mapState(['videos'])
	},
	methods: {
		selectVideo(id) {
			if (!this.videos[id].locked) {
				this.$emit('close')
				this.$emit('clicked', id)
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-list-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 40px;
	width: 200px;
	z-index: 10;
	transform: translateX(100%);
	transition: 0.5s;

	&.active {
		transform: translateX(0);
	}
}
.videoActive .video-list-container {
	bottom: 80px;
}
.video-list {
	list-style: none;
	margin: 0;
	padding: 0;
	color: $white;
	height: 100%;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		display: none;
	}

	li {
		position: relative;
		width: 200px;
		height: 120px;
		background: #ccc;
		margin: 8px 0;
		cursor: pointer;
		overflow: hidden;

		&:not(.locked):hover .thumbnail {
			transform: scale(1.2);
		}

		.thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			transition: 0.25s;
		}

		.thumbnail-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #194e89;
			opacity: 0.4;
		}

		&.locked {
			cursor: default;
			.thumbnail {
				filter: grayscale(100%);
			}
		}

		.soon {
			position: absolute;
			top: 15px;
			left: 15px;
			font-size: 10px;
			line-height: 10px;
			text-transform: uppercase;
			padding-left: 12px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: -1px;
				left: 0;
				height: 10px;
				width: 8px;
				background: url('../assets/img/lock.svg');
			}
		}

		.title {
			position: absolute;
			bottom: 15px;
			left: 15px;
			width: 120px;
			font-size: 15px;
			line-height: 15px;
			text-transform: uppercase;
			font-weight: $heavy;

			.episode-number {
				color: $yellow;
				font-weight: $normal;
			}
		}

		.progress-bar {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 5px;
			background: $orange;
		}
	}
}
@media only screen and (max-width: 767px) {
	.video-list-container {
		width: 100%;
		background-color: $cream;
	}
	.video-list {
		li {
			width: 100%;
			height: 0;
			padding-bottom: 50%;
		}
	}
}
</style>
