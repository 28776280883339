<template>
	<div class="about-container">
		<div class="close" @click="$emit('close')"></div>
		<div class="about-container__scroller">
			<div class="about-wrapper">
				<h2>Introduction</h2>
				<p>
					When a city like Norfolk, VA has 600+ indie restaurants, you can’t not
					try to try them all. So we enlisted foodie fanatic, Nomarama, to hit
					the streets to uncover the stories, the characters, and oh yes, the
					food, that gives NFK its one-of-a-kind flavor. From tiki-inspired
					cocktails to the quintessential mom and pop sub shop, elevated menus
					to low and slow barbecue, it’s easy to see why Norfolk’s the City with
					Bite.
				</p>
				<h2 class="nomarama">Meet<br />Nomarama</h2>
				<div class="nomarama-links">
					<a href="https://nomarama.com/" target="_blank">Website</a> |
					<a href="https://www.instagram.com/_nomarama_/" target="_blank"
						>Instagram</a
					>
					|
					<a href="https://www.facebook.com/TheNomarama" target="_blank"
						>Facebook</a
					>
				</div>
				<p>
					The first one to ask for seconds, Jarrell Williams aka. Nomarama, is a
					tireless tastemaker with a love of all things local. Be it a new
					restaurant opening or a hidden neighborhood gem, Nomarama has a knack
					for discovering those must-try spots and sharing them, and their
					amazing food, with the people.
				</p>
				<p>
					Follow him on City with Bite as he eats his way through Norfolk and
					serves up a little local love, some secret recipes, and a happy
					helping of good times.
				</p>
			</div>
			<div class="about-footer">
				<div class="ctas">
					<a
						@click="trackClick('restaurants')"
						href="https://www.visitnorfolk.com/eat-drink/restaurants/"
						class="cta1"
						><span>Explore<br />restaurants</span></a
					>
					<a
						@click="trackClick('city-guide')"
						href="https://www.visitnorfolk.com/plan-your-trip/request-visitors-guide/"
						class="cta2"
						><span>Download your<br />free city guide</span></a
					>
				</div>
				<div class="social-icons">
					<a href="https://www.facebook.com/VisitNorfolkToday" target="_blank"
						><img src="@/assets/img/facebook.svg"
					/></a>
					<a href="https://twitter.com/VisitNorfolkVA" target="_blank"
						><img src="@/assets/img/twitter.svg"
					/></a>
					<a
						href="https://www.youtube.com/user/VisitNorfolkVirginia"
						target="_blank"
						><img src="@/assets/img/youtube.svg"
					/></a>
					<a href="https://www.instagram.com/visitnorfolkva/" target="_blank"
						><img src="@/assets/img/instagram.svg"
					/></a>
					<a href="https://www.pinterest.com/visitnorfolk/" target="_blank"
						><img src="@/assets/img/pinterest.svg"
					/></a>
				</div>
				<div class="lovers-logo">
					<a
						@click="trackClick('virginia-is-for-lovers')"
						href="https://www.virginia.org/"
						target="_blank"
						><img src="@/assets/img/lovers-logo.svg"
					/></a>
				</div>
				<div class="copyright">
					<a href="https://www.visitnorfolk.com/privacy-policy/" target="_blank"
						>Privacy Policy</a
					>
					| Copyright 2021
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'About',
	methods: {
		trackClick(label) {
			this.$gtag.event('click', {
				event_category: 'Links',
				event_label: label
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 480px;
	background: $cream url('../assets/img/dot-bg.png') repeat-y top left;
	z-index: 10;
	transform: translateX(100%);
	transition: 0.5s;

	&__scroller {
		height: 100%;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&.active {
		transform: translateX(0);
	}
}
.about-wrapper {
	font-size: 14px;
	line-height: 20px;
	font-weight: $light;
	letter-spacing: 0.02em;
	padding: 50px;

	h2 {
		font-weight: $bold;
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: $orange;

		&.nomarama {
			position: relative;
			padding-left: 75px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 62px;
				width: 60px;
				background: url('../assets/img/nomarama-logo.png') no-repeat center;
				background-size: contain;
			}
		}
	}

	.nomarama-links {
		font-family: $komu;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $blue;

		a {
			color: $blue;
			text-decoration: none;
		}
	}
}
.about-footer {
	position: relative;
	min-height: 275px;
	background: url('../assets/img/about-footer-bg.png') repeat-x bottom center;
	background-size: contain;
	color: $white;
	padding-bottom: 50px;

	.ctas {
		text-align: center;
		margin-bottom: 20px;

		a {
			display: inline-block;
			position: relative;
			width: 131px;
			height: 195px;
			font-family: $komu;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			text-decoration: none;
			vertical-align: bottom;
			color: $white;
			margin: 0 20px;
			transition: 0.25s;

			&:hover {
				transform: scale(1.1);
			}

			span {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			&.cta1 {
				background: url('../assets/img/cta-1.png') no-repeat bottom center;
				background-size: contain;
			}
			&.cta2 {
				background: url('../assets/img/cta-2.png') no-repeat bottom center;
				background-size: contain;
			}
		}
	}
	.social-icons {
		height: 60px;
		width: 60%;
		float: left;
		text-align: center;
		padding: 15px 0;

		a {
			height: 30px;
			display: inline-block;
			margin: 0 4px;
			vertical-align: middle;

			transition: 0.25s;

			&:hover {
				transform: scale(1.2);
			}
		}
	}
	.lovers-logo {
		display: block;
		height: 60px;
		margin-bottom: 20px;
	}
	.copyright {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 50px;
		right: 200px;
		font-family: $komu;
		line-height: 50px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		text-align: center;
		letter-spacing: 0.1em;

		a {
			color: $white;
			text-decoration: none;
		}
	}
}
@media only screen and (max-width: 767px) {
	.about-container {
		width: 100%;
		bottom: 50px;
	}
	.about-footer {
		padding-bottom: 0;

		.copyright {
			position: relative;
		}
	}
}
</style>
